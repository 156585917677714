import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `

<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">AnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[AnnotationToolbar setButtonForTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)setButtonForTool:(nonnull Tool *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (void)setButtonForTool:(nullable Tool *)tool</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">AnnotEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> AnnotEditToolDelegate</div>
<div class="difference"><span class="status added">Added</span> -[AnnotEditToolDelegate annotEditTool:didBeginEditingFreeText:withTextView:]</div>
<div class="difference"><span class="status added">Added</span> AnnotEditTool.delegate</div>
<div class="difference"><span class="status added">Added</span> -[AnnotEditTool selectAnnotation:onPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotType</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeText</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeLink</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeFreeText</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeLine</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeSquare</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeCircle</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypePolygon</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypePolyline</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeHighlight</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeUnderline</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeSquiggly</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeStrikeOut</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeStamp</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeCaret</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeInk</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypePopup</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeFileAttachment</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeSound</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeMovie</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeWidget</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeScreen</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypePrinterMark</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeTrapNet</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeWatermark</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotType3D</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeRedact</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeProjection</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeRichMedia</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeArrow</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeSignature</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeCloudy</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeUnknown</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotName</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameText</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameLink</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameFreeText</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameLine</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameSquare</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameCircle</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNamePolygon</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNamePolyline</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameHighlight</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameUnderline</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameSquiggly</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameStrikeout</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameStamp</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameCaret</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameInk</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNamePopup</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameFileAttachment</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameSound</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameMovie</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameWidget</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameScreen</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNamePrinterMark</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameTrapNet</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameWatermark</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotName3D</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameRedact</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameProjection</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameRichMedia</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameArrow</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameSignature</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameCloudy</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeFromName()</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameFromType()</div>
<div class="difference"><span class="status added">Added</span> PTAnnot.extendedAnnotType</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolType</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolTypeNone</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolTypeTextSelection</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolTypeFormFilling</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolTypeLinkFollowing</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolTypeEraser</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolName</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolNameTextSelection</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolNameFormFilling</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolNameLinkFollowing</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolNameEraser</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolTypeFromName()</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolNameFromType()</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Permissions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotPermissionKey</div>
<div class="difference"><span class="status added">Added</span> PTAnnotPermissionKeyCanCreate</div>
<div class="difference"><span class="status added">Added</span> PTAnnotPermissionKeyCanEdit</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolPermissionKey</div>
<div class="difference"><span class="status added">Added</span> PTInteractionToolPermissionKeyEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.pdfViewCtrl</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.toolManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTModel.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTModel</div>
<div class="difference"><span class="status added">Added</span> PTModel.propertyKeys</div>
<div class="difference"><span class="status added">Added</span> PTModel.dictionaryValue</div>
<div class="difference"><span class="status added">Added</span> -[PTModel initWithDictionary:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTTabbedDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController configureWithToolManagerConfiguration:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">SelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> SelectionRectContainerView.textView</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">TextMarkupEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[TextMarkupEditTool selectTextMarkupAnnotation:onPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolPermissionsDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTToolPermissionsDelegate tool:canCreateExtendedAnnotType:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolPermissionsDelegate tool:canEditAnnotation:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolPermissionsDelegate tool:canEditExtendedAnnotType:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolPermissionsDelegate isTextSelectionEnabledForTool:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolPermissionsDelegate isFormFillingEnabledForTool:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolPermissionsDelegate isLinkFollowingEnabledForTool:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolPermissionsDelegate isEraserEnabledForTool:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager selectAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager configuration]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager configureWithConfiguration:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager updateConfiguration:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager canCreateExtendedAnnotType:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager canEditExtendedAnnotType:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager setPermissionsForExtendedAnnotType:canCreate:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager setPermissionsForExtendedAnnotType:canEdit:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager setPermissionsForExtendedAnnotType:canCreate:canEdit:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager isInteractionToolEnabled:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager setPermissionForInteractionToolType:enabled:]</div>
<div class="difference"><span class="status added">Added</span> ToolManager.textSelectionEnabled</div>
<div class="difference"><span class="status added">Added</span> ToolManager.formFillingEnabled</div>
<div class="difference"><span class="status added">Added</span> ToolManager.linkFollowingEnabled</div>
<div class="difference"><span class="status added">Added</span> ToolManager.eraserEnabled</div>
<div class="difference"><span class="status added">Added</span> -[ToolManagerDelegate toolManager:handleTap:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManagerDelegate toolManager:handleDoubleTap:]</div>
<div class="difference"><span class="status added">Added</span> -[ToolManagerDelegate toolManager:handleLongPress:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolSwitching.toolManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, weak, nullable) id&lt;PTToolManager&gt; toolManager</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak, nullable) id&lt;PTToolManager,PTToolPermissionsDelegate&gt; toolManager</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolSwitching initWithPDFViewCtrl:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull id)initWithPDFViewCtrl:(nonnull PTPDFViewCtrl *)in_pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithPDFViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolSwitching getNewTool]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull UIView&lt;PTToolSwitching&gt; *)getNewTool</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)getNewTool</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> ToolManager</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTTool, PTToolManager</td></tr>
<tr><th>To</th><td>PTTool, PTToolManager, PTToolPermissionsDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolManagerConfiguration.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ToolManagerConfiguration</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfiguration.showMenuOnTap</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfiguration.showDefaultSignature</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfiguration.annotPermissions</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfiguration.interactionToolPermissions</div>
<div class="difference"><span class="status added">Added</span> +[ToolManagerConfiguration configurationWithBuilder:]</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfigurationBuilder</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfigurationBuilder.showMenuOnTap</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfigurationBuilder.showDefaultSignature</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfigurationBuilder.annotPermissions</div>
<div class="difference"><span class="status added">Added</span> ToolManagerConfigurationBuilder.interactionToolPermissions</div>
<div class="difference"><span class="status added">Added</span> -[ToolManagerConfigurationBuilder build]</div>
</div>

</div>

</body>
</html>


`


export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}

